<template>
  <div class="is-relative" style="overflow: hidden" id='website'>
    <div :class="['darkLayer', {'active' : dark}]"></div>
    <div class="decorationContainer">
      <img src="/images/decorations/bottom.svg" style="position: absolute; bottom: -100px; z-index: 1; right: 100px">
      <img src="/images/decorations/zawijasy.svg" style="position: absolute; top: 100vh; z-index: 1">
    </div>
    <Header @dark="changeDark"></Header>
    <section class="section" style="margin-top: 8rem" id="naszeZalety" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle">Nasze zalety</h1>
        <div class="columns mt-6">
          <div class="column is-4 botInfo" data-aos="fade-up-right">
            <font-awesome-icon icon="fa-solid fa-gauge-high" class="iconn"></font-awesome-icon><br>
            <span class="header">Wydajność</span><br>
            <span class="desc">Dbamy o wydajność bota, aby działał bez problemów!</span>
          </div>
          <div class="column is-4 botInfo" data-aos="fade-up">
            <font-awesome-icon icon="fa-solid fa-bolt" class="iconn"></font-awesome-icon><br>
            <span class="header">Szybka naprawa błędów</span><br>
            <span class="desc">Naprawimy błędy bota w 1 dzień!</span>
          </div>
          <div class="column is-4 botInfo" data-aos="fade-up-left">
            <font-awesome-icon icon="fa-solid fa-lock" class="iconn"></font-awesome-icon><br>
            <span class="header">Bezpieczeństwo</span><br>
            <span class="desc">Token bota jest bezpiecznie przechowywany!</span>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="zaufaliNam" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mb-6">Partnerstwa</h1>
        <div class="is-flex is-justify-content-center">
          <Carousel :items="items"></Carousel>
        </div>
      </div>
    </section>
    <section class="section" id="developerBota" data-aos="fade-down">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mt-4">Developer</h1>
        <h1 class="subtitle is-4" style="color: var(--primary);font-weight: 800;">! Pimpus#0001</h1>
        <div class="aboutDev">
          <img :src="getENV('AVATAR')">
          <span>
            Witaj jestem developerem bota Pikatchu!<br>
            Chciałbyś nawiązać ze mną kontakt lub współpracę<br>
            to wbij na <a :href="getENV('DISCORD')" target="_blank">Discorda</a> i napisz do mnie!
          </span>
        </div>
      </div>
    </section>  
    <section style="margin-bottom: 6rem" data-aos="zoom-in">
      <div class="container has-text-centered">
        <a class="btn dc" :href="getENV('DISCORD')" target="_blank">Dołącz na Discorda!</a>
      </div>
    </section>
    <Footer name="pikatchu.ml" color="#fad61d"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Carousel from '../components/Carousel.vue'
import Footer from '../components/Footer.vue'
import { getENV } from '@/utils.js'

export default {
  name: 'Home',
  components: {
    Header,
    Carousel,
    Footer
  }, data() {
    return {
      items: [
        {img: 'https://pikatchu.ml/images/widzowie-liska.png', link: 'https://discord.gg/GZTHKznWqN', name: '🦊 | Widzowie Liska'},
        {img: 'https://pikatchu.ml/images/Area.png', link: 'https://discord.gg/eQ3pXBHwpg', name: 'Area'},
        {img: 'https://pikatchu.ml/images/galaxy29.jpg', link: 'https://discord.gg/zknDfWf3rU ', name: 'GALAXY COMMUNITY'},
        {img: 'https://pikatchu.ml/images/Last-City.png', link: 'https://discord.gg/DhPhHEBEcT', name: 'Last City'},
        {img: 'https://pikatchu.ml/images/AurilBot.png', link: 'https://discord.gg/4ugHqEyFab', name: 'AurilBot'},
        {img: 'https://pikatchu.ml/images/krzysio.png', link: 'https://discord.gg/Y5Gcbc63De', name: 'FanClub krzysio509'},
        {img: 'https://pikatchu.ml/images/flay.gif', link: 'https://discord.gg/2S56gX3r2G', name: 'Flay'},
        {img: 'https://pikatchu.ml/images/przyjaciele-klamerki.gif', link: 'https://discord.gg/dPdMSacXJG', name: 'Przyjaciele Klamerk'},
        {img: 'https://pikatchu.ml/images/katastrofa.gif', link: 'https://discord.gg/katastrofa', name: 'katastrofa'},
        {img: 'https://pikatchu.ml/images/fivem.png', link: 'https://discord.gg/YzGg5QasUd', name: 'FiveM Items/Skins - Community'},
        {img: 'https://pikatchu.ml/images/myhost.png', link: 'https://panel.MayHost.pl', name: 'MayHost'},
      ], dark: false
    }
  }, methods: {
    changeDark(value) {
      this.dark = value;
    }, getENV
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

#website {
  background-color: #1b1b1b;
}

.decorationContainer {
  position: absolute; 
  width: 1800px; 
  left: 50%;
  transform: translateX(-50%); 
  top: 0; 
  bottom: 0;
}

.botInfo {
  display: block;
  .iconn {
    font-size: 2rem;
    color: var(--primary)
  }

  .header {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 800;
  }

  .desc {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.05rem;
  }
}

.aboutDev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @include touch {
    gap: 25px;
    flex-direction: column;
  }
  // margin-left: auto;
  // margin-right: auto;

  img {
    border-radius: 50%;
    border: var(--primary) solid 3px;
    height: 200px;

    @include touch {
      // height: clamp(100px, 15vw, 175px);
      height: clamp(120px, 20vw, 175px);
      // height: 18vw;
    }
  }

  span {
      white-space: pre-wrap;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;

      @include touch {
        font-size: clamp(1rem, 3vw, 1.25rem);
        // font-size: 2vw;
        // font-size: 1.25rem;
        // font-size: 1rem;
      }
  }

  a {
    transition: color .2s;

    &:hover {
      color: var(--primary)
    }
  }
}

.section {
  margin-bottom: 15rem;
  // z-index: 0;
  // position: relative;

  @include touch {
    margin-bottom: 5rem;
  }
}

.sectionTitle {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.darkLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;

  backdrop-filter: brightness(100%) opacity(0);

  transition: z-index .5s step-end, backdrop-filter .5s linear;

  &.active {
    z-index: 3;
    backdrop-filter: brightness(50%) opacity(1);

    transition: z-index .5s step-start, backdrop-filter .5s linear;
  }
}
</style>
