<template>
  <div></div>
</template>

<script>
export default {
    created() {
    window.location.replace('https://discord.gg/hzuaAVA3');
    }
}
</script>

<style>

</style>
