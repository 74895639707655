<template>
  <div></div>
</template>

<script>
export default {
    created() {
    window.location.replace('https://discord.com/oauth2/authorize?client_id=983739132335493202&permissions=1945627743&scope=applications.commands%20bot');
    }
}
</script>

<style>
</style>
