<template>
  <div></div>
</template>

<script>
export default {
    created() {
    window.location.replace('https://youtu.be/gpjla9aaak4');
    }
}
</script>

<style>
</style>
