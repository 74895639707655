<template>
  <div class="navv">
    <img src="/images/logo.svg" class="logo">
    <div class="info">
      <div :class="['tongue', {'active': active}]">
        <ul class="navItems">
          <li><router-link class="navLink" to="/">Strona główna</router-link></li>
          <li><router-link class="navLink" to="/spis">Spis komend</router-link></li>
          <li><router-link class="navLink" to="/yt">Reklama</router-link></li>
          <li><a class="btnn" :href="getENV('DISCORD')">Dołącz</a></li>
        </ul>
      </div>
    </div>
    <div class="lines" @click="active = !active">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { getENV, scrollTo } from '@/utils.js'

export default {
  data() {
    return {
      active: false
    }
  }, methods: {
    getENV,
    preventScroll(e){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }, scroll(destination) {
      document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      scrollTo(destination);
      this.active = false;
    }
  }, watch: {
    active(value) {
      this.$emit('dark', value)
      if(value) document.addEventListener('touchmove', this.preventScroll, { passive:false });
      else document.removeEventListener('touchmove', this.preventScroll, { passive: false });
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";


.logo, .lines {
  z-index: 7;
}

.navv {
    padding: 40px 5px;
    display: flex;
    align-items: center;
    width: 101%;

    @include until-fullhd {
      padding: 40px 75px;
    }
	
    @include until(1600px) {
      padding: 40px 35px;
    }

    // @include until-widescreen {
    //   padding: 40px 5px;
    // }
}

.info {
  margin-left: auto;

  @include until-widescreen {
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    // width: 100%;
  }
}

.tongue {
  @include until-widescreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 330px;
    background-color: #000;
    border-radius: 0 0 64px 64px;
    z-index: 6;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    transition: transform .5s;

    transform: translateY(-110%);
  }

  &.active {
    transform: translateY(-5%);
  }
}

.navItems {
  display: inline-flex;
  align-items: center;

  @include until-widescreen {
    display: block;
    padding-bottom: 10px;
  }
}

.navItems > li {
  display: inline;
  padding: 0 25px;
  font-weight: 600;

  @include until-fullhd {
    display: block;
    padding: 0 20px;
    text-align: center;
  }

  @include until-widescreen {
    display: block;
    padding: 5px 0;
    text-align: center;
  }
}

.navLink {
  color: var(--primary);
  transition: color .5s;
  font-weight: 600;
  font-size: 0.95rem;

  @include until-widescreen {
    font-size: 1.3rem;
    color: #fff;
  }
}

.navLink:hover {
  color: #fff;
}

.btnn {
  padding: 8px 40px;
  background-color: #000;
  color: #fefefe;
  font-weight: 800;
  font-size: 1.1rem;
  border-radius: 16px;

  transition: background-color .5s;

  &:hover {
    background-color: var(--primary);
    color: #000;
  }

  @include until-widescreen {
    background-color: var(--primary);
    color: #000;
  }
}

.lines {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  @include until-widescreen {
    visibility: visible;
  }

  .line {
    background-color: #fff;
    width: 30px;
    height: 5px;
    border-radius: 16px;
  }
}
</style>
