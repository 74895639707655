<template>
  <div></div>
</template>

<script>
export default {
    created() {
    window.location.replace('https://www.youtube.com/watch?v=MPmHqXhZAWs');
    }
}
</script>

<style>

</style>
