import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reklama from '../views/reklama.vue'
import Lisek45 from '../views/widzowieliska.vue'
//import command from '../views/Spis.vue'
import yt from '../views/youtube.vue'
import addbot from '../views/dodajbota.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
 {
    path: '/yt',
    name: 'youtube',
    component: yt
  },
  {
    path: '/reklama',
    name: 'reklama',
    component: Reklama
  },
  {
    path: '/widzowieliska',
    name: 'widzowielisek',
    component: Lisek45
  },
  {
    path: '/addbot',
    name: 'addbot',
    component: addbot
  },
  {path: "*", redirect: "/" }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
